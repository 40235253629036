// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery, convertTag } from "src/utils/baseQuery";
// configs
import { ENDPOINTS, METHODS } from "src/constants/API";
import { apiHelper } from "src/utils/apiHelper";

const TAGS = {
  list: "tag-transaction-list",
};

export const TransactionApi = createApi({
  reducerPath: "transaction-api",
  baseQuery: baseQuery(true),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    // USER LIST
    getTransactionList: builder.query({
      providesTags: [convertTag(TAGS.list)],
      query: (body) => apiHelper(ENDPOINTS.transaction.list, body),
    }),
    getTransaction: builder.query({
      query: (body) => apiHelper(ENDPOINTS.transaction.singleTransaction, body),
    }),
    syncInvoice: builder.mutation({
      invalidatesTags: [convertTag(TAGS.list)],

      query: (body) => ({
        url: apiHelper(ENDPOINTS.transaction.syncInvoice, body),
        method: METHODS.get,
      }),
    }),
  }),
});

export const {
  useGetTransactionListQuery,
  useSyncInvoiceMutation,
  useGetTransactionQuery,
} = TransactionApi;
