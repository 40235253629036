import axios from "axios";
import { getSession } from "src/utils/jwt";

export const BASE_URL = process.env.REACT_APP_DEVELOPMENT_MODE
  ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
  : process.env.REACT_APP_BASE_URL;

export const Axios = axios.create({
  baseURL: BASE_URL,
  headers: { lt: getSession(), ln: "en" },
  timeout: 0,
});

export enum METHODS {
  get = "GET",
  post = "POST",
}

export const ENDPOINTS = {
  auth: { login: "/auth/login" },
  user: {
    list: "/admin/user/list",
    update: "/admin/user/update",
    charge: "/admin/user/addGFT",
  },
  transaction: {
    list: "/admin/user-wallet-transaction/list",
    syncInvoice: "/admin/invoice/sync",
    downloadInvoice: "/payment/invoice/pdf",
    singleTransaction: "/transaction/get",
  },
  product: {
    list: "/admin/product/list",
    create: "/admin/product/create",
    mint: "/admin/product/mint-token",
    nft: "/admin/product/mint-nft",
    update: "/admin/product/update",
    deleteMedia: "/admin/product/delete-photo",
    getJetton: "/admin/product/get-jetton",
    getProduct: "/admin/product/detail",
    removeFromSale: "/admin/product/removeFromSale",
    reserveToken: "/admin/product/reserve/token",
    editReserveToken: "/admin/product/reserve/token/edit",
    deleteReserveToken: "/admin/product/reserve/token/remove",
  },
  project: {
    list: "/admin/project/list",
    single: "/project/admin/get",
    certificate: "/admin/certificate/get",
  },
  media: {
    upload: "/admin/upload-image",
    get: "/media/get",
    pdf: "/admin/upload-pdf",
  },
};
